<template>
	<div>
		<div class="finish-msg" v-html="$t('register.step.success')"></div>
		<categories-access></categories-access>
	</div>
</template>

<script>
	const CategoriesAccess = () => import('@/components/CategoriesAccess/categories');

	export default {
		components: {
			CategoriesAccess
		},
		data() {
			return {

			};
		},
		methods: {
			async nextStep() {
				return new Promise((resolve, reject) => {
					this.$router.push({
						path: '/dashboard/index'
					}).catch(() => {});
					resolve();
				});
			},
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		destroyed() {}
	};
</script>

<style scoped>
	.finish-msg {
		padding: 15px 10px;
		text-align: center;
		margin-bottom: 15px;
		background: #f7f7f7;
		border-radius: 4px;
		color: #369c03;
		border: 1px #f1f1f1 solid;
	}
</style>